// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { Button } from 'antd';
import { Flex } from 'components/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterWapper } from '../FilterWapper';
import { FilterTitle } from '../styled';

const EquipmentFilter = () => {
  const { t } = useTranslation();
  return (
    <FilterWapper>
      <Flex justifyContent='space-between' alignItems='center'>
        <FilterTitle>{t('filter_Filter')}</FilterTitle>
        <Button type='link' size='small'>
          {t('filter_Clear Filter')}
        </Button>
      </Flex>
    </FilterWapper>
  );
};

export default EquipmentFilter;
