// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { env } from 'config';
import { useCallback, useEffect, useState } from 'react';
import { GoldRushBid, SUPPORTED_LANDS_INDEX } from 'types';
import { $get } from 'utils/agent';
import { StatusResponse } from './types';

interface GetGoldRushBidsResponse extends StatusResponse {
  data: GoldRushBid[];
}

// FIXME: row is required
const UNLIMITED = 9999;

export function useGetGoldRushBids(landId: SUPPORTED_LANDS_INDEX, eventId: number) {
  const [isFetching, setIsFetching] = useState(false);
  const [bids, setBids] = useState<GoldRushBid[]>([]);

  const query = useCallback(
    async (id: number = eventId) => {
      if (!id) {
        return null;
      }

      setIsFetching(true);

      try {
        const { data }: GetGoldRushBidsResponse = await $get('/api/gold_rush/bids', {
          'EVO-NETWORK': env[landId].BACKEND_API_NETWORK,
          ignore_network: true,
          event_id: id,
          row: UNLIMITED
        });

        setBids(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }

      return null;
    },
    [landId, eventId]
  );

  useEffect(() => {
    query();
  }, [query]);

  return { bids, query, isFetching };
}
