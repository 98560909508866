// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import styled from 'styled-components';
import { MysteriousShopBoxProps } from '../types';
import { StyledContainer, StyledHeader, StyledSubtitle, StyledNumberBox, StyledNFTBox, StyledNFTImg } from '../styled';

const FurnaceTreasureBox: React.FC<MysteriousShopBoxProps> = ({ title, subtitle, imageUrl, footerNode, className, imageOnClick }) => {
  return (
    <StyledContainer pointer className={className} onClick={imageOnClick}>
      <StyledHeader>
        <StyledNumberBox>{title}</StyledNumberBox>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </StyledHeader>
      <StyledNFTBox>
        <StyledNFTImg src={imageUrl} alt='...' />
      </StyledNFTBox>
      {footerNode && footerNode}
    </StyledContainer>
  );
};

export default styled(FurnaceTreasureBox)`

`;
