// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { $get } from 'utils/agent';
import { env } from 'config';
import { useCallback, useState } from 'react';
import { EquipmentDetail, SUPPORTED_LANDS_INDEX } from 'types';
import { remove0x } from 'api/utils';
import { StatusResponse } from './types';

interface GetEquipmentOption {
  tokenId: string;
}

interface GetEquipmentResponse extends StatusResponse {
  data: EquipmentDetail;
}

export const useGetEquipment = (landId: SUPPORTED_LANDS_INDEX) => {
  const [isLoading, setIsLoading] = useState(false);

  const query = useCallback(
    async ({ tokenId }: GetEquipmentOption) => {
      setIsLoading(true);

      try {
        const res: GetEquipmentResponse = await $get('/api/equipment/info', {
          'EVO-NETWORK': env[landId].BACKEND_API_NETWORK,
          token_id: remove0x(tokenId)
        });
        return res;
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }

      return null;
    },
    [landId]
  );

  return { query, isLoading };
};
