// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { $get } from 'utils/agent';
import { env } from 'config';
import { useCallback, useState } from 'react';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { Equipment, SUPPORTED_LANDS_INDEX } from 'types';
import { StatusResponse } from './types';

interface GetEquipmentListOption {
  order: string;
  type: string;
  page: number;
  size: number;
}

interface GetEquipmentListResponse extends StatusResponse {
  count: number;
  data: Equipment[];
}

export const useGetEquipmentList = (landId: SUPPORTED_LANDS_INDEX) => {
  const { account: owner } = useActiveWeb3React(landId);
  const [isLoading, setIsLoading] = useState(false);

  const query = useCallback(
    async ({ order, type, page, size }: GetEquipmentListOption) => {
      if (!owner) {
        return null;
      }

      setIsLoading(true);
      try {
        const res: GetEquipmentListResponse = await $get('/api/equipment/list', {
          'EVO-NETWORK': env[landId].BACKEND_API_NETWORK,
          order,
          object: type,
          page,
          row: size,
          owner
        });
        return res;
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }

      return null;
    },
    [owner, landId]
  );

  return { query, isLoading };
};
