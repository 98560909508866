// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { useTooltip } from 'hooks/useTooltip';
import { Flex } from 'components/Box';

import { StyledContainer, StyledHeader, StyledSubtitle, StyledNumberBox, StyledNFTBox, StyledStatusBar, StyledNFTImg, StyledSubtitleIcon } from '../styled';
import { EquipmentBoxProps } from '../types';
import { Status } from '../Status';
import { EquipmentTooltip } from '../Tooltip';

const EquipmentBox = ({ className, info, onClick = () => ({}), pointer = true, hasTooltip = true, status = [] }: EquipmentBoxProps) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<EquipmentTooltip info={info} />, { placement: 'auto', trigger: 'hover' });

  return (
    <StyledContainer pointer={pointer} className={className} onClick={onClick}>
      <StyledHeader>
        <StyledNumberBox>{info.name}</StyledNumberBox>
        {info.element && (
          <StyledSubtitle>
            <Flex alignItems='center'>
              <StyledSubtitleIcon src={info.elementImage} alt={`${info.element.toLowerCase()} token`} />
            </Flex>
          </StyledSubtitle>
        )}
      </StyledHeader>
      <StyledNFTBox ref={targetRef}>
        <StyledNFTImg src={info.image} alt={info.name} />
        <StyledStatusBar>
          {status.map((item) => (
            <Status statusKey={item} key={item} />
          ))}
        </StyledStatusBar>
      </StyledNFTBox>
      {hasTooltip && tooltipVisible && tooltip}
    </StyledContainer>
  );
};

export default EquipmentBox;
