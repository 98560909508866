// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { $get } from 'utils/agent';
import { env } from 'config';
import { useCallback, useEffect, useState } from 'react';
import { ChainName, GoldRushEvent, SUPPORTED_LANDS_INDEX } from 'types';
import unionBy from 'lodash/unionBy';
import { StatusResponse } from './types';

interface GetGoldRushEventsResponse extends StatusResponse {
  data: GoldRushEvent[];
}

const networks: Record<SUPPORTED_LANDS_INDEX, ChainName> = {
  '1': 'Eth',
  '2': 'Tron',
  '3': 'Crab',
  '4': 'Heco',
  '5': 'Polygon'
};

export const useGetGoldRushEvents = (landId: SUPPORTED_LANDS_INDEX) => {
  const [isFetching, setIsFetching] = useState(false);
  const [events, setEvents] = useState<GoldRushEvent[]>([]);

  const query = useCallback(async () => {
    setIsFetching(true);

    try {
      const { data }: GetGoldRushEventsResponse = await $get('/api/gold_rush/events', {
        'EVO-NETWORK': env[landId].BACKEND_API_NETWORK
      });

      // NOTE: Dirty data removal
      setEvents(unionBy(data, ({ from_chain, id }) => from_chain + id));
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetching(false);
    }

    return null;
  }, [landId]);

  useEffect(() => {
    query();
  }, [query]);

  const filterEvents = useCallback((from: SUPPORTED_LANDS_INDEX, eventId?: number) => events.filter(({ from_chain, id }) => networks[from] === from_chain && (!eventId || eventId === id)), [events]);

  return { events, query, filterEvents, isFetching };
};
