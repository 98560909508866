// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import styled from 'styled-components';
import { BareProps } from 'components/types';
import { MaterialInfo } from 'types';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from '../../Box';
import { StyledLabel, StyleTooltipLine } from '../styled';

export interface Props extends BareProps {
  info: MaterialInfo;
}

const StyledMaterialTooltip = styled.div`
  align-items: center;
  width: 12.5rem;
`;

const MaterialTooltip: React.FC<Props> = ({ className, info }) => {
  const { t } = useTranslation();

  return (
    <StyledMaterialTooltip className={className}>
      <Box className='space-y-1 w-full'>
        <StyleTooltipLine />
        <Flex className='space-y-1' flexDirection='column'>
          <StyledLabel>
            <span>{t(info.name)}</span>
          </StyledLabel>
          <StyledLabel>
            <span>{t('Rarity')}</span> <span>{t(info.rarity)}</span>
          </StyledLabel>
          <StyledLabel>
            <span>{t('Description')}</span>
          </StyledLabel>
          <StyledLabel>
            <span>{t(info.description)}</span>
          </StyledLabel>
        </Flex>
      </Box>
    </StyledMaterialTooltip>
  );
};

export default MaterialTooltip;
