// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import styled from 'styled-components';
import { BareProps } from 'components/types';
import { EquipmentInfo } from 'types';
import { Flex, Box } from '../../Box';
import { StyledLabel, StyleTooltipLine } from '../styled';

export interface Props extends BareProps {
  info: EquipmentInfo;
}

const StyledEquipmentTooltip = styled.div`
  align-items: center;
  width: 140px;
`;

const EquipmentTooltip: React.FC<Props> = ({ className, info }) => {
  return (
    <StyledEquipmentTooltip className={className}>
      <Box className='space-y-1 w-full'>
        <StyleTooltipLine />
        <Flex className='space-y-1' flexDirection='column'>
          <StyledLabel>
            <span>{info.name}</span>
          </StyledLabel>
          <StyledLabel>
            <span>{info.attribute}</span> <span>+{info.attributeValue}</span>
          </StyledLabel>
          <StyledLabel>
            <span>{info.element}</span> <span>+{info.elementValue}</span>
          </StyledLabel>
        </Flex>
      </Box>
    </StyledEquipmentTooltip>
  );
};

export default EquipmentTooltip;
