// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useState } from 'react';
import _ from 'lodash';
import { $get, $post } from 'utils/agent';
import { env } from 'config';
import { SUPPORTED_LANDS_INDEX } from 'types';
import { bundleApi } from 'api';
import { StatusResponse, FurnaceTreasure } from './types';
import { FETCH_ERROR } from './constants';

const FurnaceTreasureList: FurnaceTreasure[] = [
  {
    id: 1,
    title: 'Silver Chest',
    type: 'silver',
    image: '/images/mysteriousshop/chest/silver.png'
  },
  {
    id: 2,
    title: 'Gold Chest',
    type: 'gold',
    image: '/images/mysteriousshop/chest/gold.png'
  }
];

export interface GetFurnaceTreasureListResponse extends StatusResponse {
  data: FurnaceTreasure[];
  count: number;
}

export interface GetFurnaceTreasureResponse extends StatusResponse {
  data: FurnaceTreasure;
}

export interface GetFurnaceTreasureRequest {
  tokenId: string;
}

export interface GetMyChestListRequest {
  page: number;
  row: number;
  address?: string;
  landId: SUPPORTED_LANDS_INDEX;
  filter?: 'locked' | '';
}

export const useGetFurnaceTreasureList = () => {
  const [data, setData] = useState<GetFurnaceTreasureListResponse | null>(null);

  useEffect(() => {
    setData({
      code: 0,
      data: FurnaceTreasureList,
      count: 2
    });
  }, [setData]);

  return { data, isLoading: false };
};

export const useGetFurnaceTreasureByTokenId = ({ tokenId }: GetFurnaceTreasureRequest) => {
  const [data, setData] = useState<FurnaceTreasure | null>(null);
  const result = _.find(FurnaceTreasureList, { id: parseInt(tokenId) }) || null;

  useEffect(() => {
    setData(result);
  }, [result, setData]);

  return { data, isLoading: false };
};

export const useGetMyChestList = ({ landId, page, row, address, filter }: GetMyChestListRequest) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const apiNetwork = env[landId].BACKEND_API_NETWORK;
        const response = await $get('/api/treasure/my', { 'EVO-NETWORK': apiNetwork, page, row, owner: bundleApi[landId].formatAddress(address), filter });
        const responseData = response;
        setData(responseData);
        setIsLoading(false);
      } catch (error) {
        console.error(FETCH_ERROR, error);
        setIsLoading(false);
      }
    };
    if (address) {
      fetchData();
    }
  }, [page, row, setData, address, landId, filter]);

  return { data, isLoading };
};

export const getFurnaceTreasureSignByTokenId = async (landId: SUPPORTED_LANDS_INDEX, tokenIds: string[], address) => {
  const apiNetwork = env[landId].BACKEND_API_NETWORK;
  const response = await $post('/api/treasure/sign_open', { box_index: tokenIds }, { params: { 'EVO-NETWORK': apiNetwork, owner: address } });
  return response;
};
