// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { Button, Radio } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from '../../Box';
import { FilterTitle } from '../styled';
import { FilterWapper } from '../FilterWapper';

type FilterModule = {
  price?: boolean;
  gens?: boolean;
  resources?: boolean;
};

interface Props {
  module?: FilterModule;
  className?: string;
  asyncSearch?: boolean;
}

const Filter: React.FC<Props> = ({ module, className, asyncSearch }) => {
  const { t } = useTranslation();

  return (
    <FilterWapper className={className}>
      <Flex justifyContent='space-between' alignItems='center'>
        <FilterTitle>{t('filter_Filter')}</FilterTitle>
        <Button type='link' size='small'>
          {t('filter_Clear Filter')}
        </Button>
      </Flex>
      <Box mt='3'>
        <Radio.Group defaultValue='general' buttonStyle='solid' className='Apostle--filter-radio'>
          {/* <Radio.Button value='general'>General</Radio.Button> */}
          {/* <Radio.Button value='mining'>Mining</Radio.Button>
          <Radio.Button value='stats'>Stats</Radio.Button> */}
        </Radio.Group>
      </Box>
    </FilterWapper>
  );
};

export default React.memo<Props>(styled(Filter)`
  .Apostle--filter-radio {
    display: flex;

    > label {
      flex: 1;
      text-align: center;
    }
  }
`);
