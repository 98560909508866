// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { useTooltip } from 'hooks/useTooltip';

import { Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { StyledContainer, StyledHeader, StyledNumberBox, StyledNFTBox, StyledNFTImg } from '../styled';
import { MaterialBoxProps } from '../types';
import { MaterialTooltip } from '../Tooltip';

const MaterialBox = ({ className, info, onClick = () => ({}), pointer = true, hasTooltip = true }: MaterialBoxProps) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<MaterialTooltip info={info} />, { placement: 'auto', trigger: 'hover' });
  const { t } = useTranslation();

  return (
    <StyledContainer pointer={pointer} className={className} onClick={onClick}>
      <StyledHeader>
        <StyledNumberBox>{t(info.name)}</StyledNumberBox>
      </StyledHeader>
      <StyledNFTBox ref={targetRef}>
        <StyledNFTImg src={info.img} alt={t(info.name)} />
      </StyledNFTBox>
      <Text small bold center>
        x{info.number}
      </Text>
      {hasTooltip && tooltipVisible && tooltip}
    </StyledContainer>
  );
};

export default MaterialBox;
